import React from "react"

import { Box } from "@mui/material"
import { DailyPickemSettings } from "components/Taiyoro/Pickem/DailyPickemSettings"
import { PickemSettingsProvider } from "components/Taiyoro/Pickem/PickemProvider"
import { PrizeSettings } from "components/Taiyoro/Pickem/PrizeSettings"
import { SettingsOptions } from "components/Taiyoro/Pickem/SettingsOptions"
import { SettingsWrapper } from "components/Taiyoro/Pickem/SettingsWrapper"
import { VisibilitySettings } from "components/Taiyoro/Pickem/VisibilitySettings"

const PickemSettingsPage = () => {
  return (
    <PickemSettingsProvider>
      <Box sx={{ height: "100%" }}>
        <SettingsWrapper>
          <SettingsOptions />
          <VisibilitySettings />
          <PrizeSettings />
          <DailyPickemSettings />
        </SettingsWrapper>
      </Box>
    </PickemSettingsProvider>
  )
}

export default PickemSettingsPage
