import type React from "react"
import { createContext } from "react"

import { initialSettings } from "components/Taiyoro/Pickem/PickemProvider"
import type Event from "models/Taiyoro/event"
import type { DailyPickemAny } from "services/Taiyoro/Pickem/daily"
import type { PickemUser } from "services/Taiyoro/Pickem/participants"
import type { Settings } from "services/Taiyoro/Pickem/settings"

type PickemContextType = {
  setSaving: (saving: boolean) => void
  loading: boolean
  eventId: string
  eventState: Event | undefined
  setEventState: (event: Event) => void
  settings: Settings
  setSettings: React.Dispatch<React.SetStateAction<Settings>>
  dailyPickemList: Array<DailyPickemAny>
  setDailyPickemList: (dailyPickemList: Array<DailyPickemAny>) => void
  participants: Array<PickemUser>
  setParticipants: (participants: Array<PickemUser>) => void
  refreshData: () => Promise<void>
}

const initialContext = {
  setSaving: () => undefined,
  loading: false,
  eventId: "",
  eventState: undefined,
  setEventState: () => undefined,
  settings: initialSettings,
  setSettings: () => undefined,
  dailyPickemList: [],
  setDailyPickemList: () => [],
  participants: [],
  setParticipants: () => undefined,
  refreshData: () => new Promise<void>(() => {}),
}

export const PickemSettingsContext = createContext<PickemContextType>(initialContext)
