import React, { useContext } from "react"

import { Save as SaveIcon } from "@mui/icons-material/"
import { Button } from "@mui/material"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import { deleteDailyPickem, saveDailyPickem } from "services/Taiyoro/Pickem/daily"
import { savePickemSettings } from "services/Taiyoro/Pickem/settings"

const SaveData = () => {
  const { t } = useTranslation("taiyoro")
  const { setSaving, dailyPickemList, settings, eventState, refreshData, loading } =
    useContext(PickemSettingsContext)

  const handleSave = async () => {
    if (!eventState?.id) return

    setSaving(true)

    const saveList = dailyPickemList.filter(
      (dailyPickem) => dailyPickem.status === "new" || dailyPickem.status === "modified"
    )

    const deleteList = dailyPickemList.filter(
      (dailyPickem) =>
        dailyPickem.status === "deletedFromSaved" || dailyPickem.status === "deletedFromModified"
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Array<Promise<any>> = []

    promises.push(
      savePickemSettings({
        ...settings,
        eventId: settings.eventId === "" ? eventState.id : settings.eventId,
      })
    )

    saveList.forEach((dailyPickem) => {
      promises.push(
        saveDailyPickem({
          dailyPickem,
        })
      )
    })

    deleteList.forEach((dailyPickem) => {
      promises.push(
        deleteDailyPickem({
          dailyPickemId: dailyPickem.id,
        })
      )
    })

    await Promise.all(promises).then(async () => {
      await refreshData()
      setSaving(false)
    })
  }

  return (
    <Button
      variant="contained"
      endIcon={<SaveIcon />}
      onClick={() => void handleSave()}
      disabled={loading}
    >
      {t("eventPickem.save")}
    </Button>
  )
}

export default SaveData
