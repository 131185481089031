import React, { useContext, useState, useCallback, useEffect, useMemo } from "react"

import { NotificationContext } from "contexts/notification"
import type { Notification } from "contexts/notification"
import { PickemSettingsContext } from "contexts/pickem"
import type Event from "models/Taiyoro/event"
import { useParams } from "react-router-dom"
import {
  fetchDailyPickemList,
  type AnswerOptions,
  type DailyPickemAny,
  type FreetextAnswer,
  type ParticipantAnswer,
} from "services/Taiyoro/Pickem/daily"
import { fetchParticipants } from "services/Taiyoro/Pickem/participants"
import type { PickemUser } from "services/Taiyoro/Pickem/participants"
import { fetchPickemSettings } from "services/Taiyoro/Pickem/settings"
import type { Settings } from "services/Taiyoro/Pickem/settings"
import { fetchEvent } from "services/Taiyoro/event"

export const initialSettings: Settings = {
  id: "",
  eventId: "",
  isPublic: false,
  prizeBannerCopy: "",
  prizeBannerCopyJa: "",
  prizeBannerImageUrl: "",
  prizePageCopy: "",
  prizePageCopyJa: "",
  prizePageImageUrl: "",
  tournamentPickem: [],
}

export const PickemSettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const { eventId } = useParams<{ eventId: string }>()
  const { setNotification } = useContext(NotificationContext)

  const [saving, setSaving] = useState(false)
  const [eventLoading, setEventLoading] = useState(true)
  const [settingsLoading, setSettingsLoading] = useState(true)
  const [dailyPickemsLoading, setDailyPickemsLoading] = useState(true)
  const [participantsLoading, setParticipantsLoading] = useState(true)
  const [eventState, setEventState] = useState<Event | undefined>(undefined)
  const [settings, setSettings] = useState<Settings>(initialSettings)
  const [dailyPickemList, setDailyPickemList] = useState<Array<DailyPickemAny>>([])
  const [participants, setParticipants] = useState<Array<PickemUser>>([])

  const loading = useMemo(() => {
    return eventLoading || settingsLoading || dailyPickemsLoading || participantsLoading || saving
  }, [eventLoading, settingsLoading, dailyPickemsLoading, participantsLoading, saving])

  const commonFetchErrorHandle = async <T,>(
    fetch: () => Promise<T>,
    setLoading: (loading: boolean) => void,
    setNotification: (notification: Notification) => void
  ): Promise<T | undefined> => {
    try {
      setLoading(true)
      const response = await fetch()
      setLoading(false)
      return response
    } catch (err) {
      setLoading(false)
      console.error("Error Fetching:", err)
      setNotification({
        message: err instanceof Error ? err.message : JSON.stringify(err),
        severity: "error",
      })
    }
  }

  const loadEvent = useCallback(async () => {
    const response = await commonFetchErrorHandle(() => fetchEvent(eventId), setEventLoading, setNotification)
    if (response) setEventState(response)
  }, [eventId, setEventLoading, setNotification])

  const loadSettings = useCallback(async () => {
    const response = await commonFetchErrorHandle(
      () => fetchPickemSettings({ eventId }),
      setSettingsLoading,
      setNotification
    )
    if (response?.status === "success" && response.data) {
      setSettings({
        ...response.data,
        isPublic: response.data.isPublic === 1 ? true : false,
        prizeBannerCopy: response.data.prizeBannerCopy ?? "",
        prizeBannerCopyJa: response.data.prizeBannerCopyJa ?? "",
        prizeBannerImageUrl: response.data.prizeBannerImageUrl ?? "",
        prizePageCopy: response.data.prizePageCopy ?? "",
        prizePageCopyJa: response.data.prizePageCopyJa ?? "",
        prizePageImageUrl: response.data.prizePageImageUrl ?? "",
        tournamentPickem: response.data.tournamentPickem ?? [],
      } as Settings)
    }
  }, [eventId, setSettingsLoading, setNotification])

  const loadDailyPickemList = useCallback(async () => {
    const response = await commonFetchErrorHandle(
      () => fetchDailyPickemList({ eventId }),
      setDailyPickemsLoading,
      setNotification
    )
    if (response?.status === "success") {
      const parsedDailyPickemList = response.data.map((dailyPickem) => {
        const parsedAnswerOptions: AnswerOptions =
          dailyPickem.answerOptions.type === "participant"
            ? {
                type: "participant",
                data: JSON.parse(dailyPickem.answerOptions.data) as Array<ParticipantAnswer>,
              }
            : {
                type: "freetext",
                data: JSON.parse(dailyPickem.answerOptions.data) as Array<FreetextAnswer>,
              }

        const parsedDailyPickem: DailyPickemAny = {
          status: "saved",
          ...dailyPickem,
          answerOptions: parsedAnswerOptions,
        }

        return parsedDailyPickem
      })
      setDailyPickemList(parsedDailyPickemList)
    }
  }, [setDailyPickemsLoading, setNotification])

  const loadParticipants = useCallback(async () => {
    const response = await commonFetchErrorHandle(
      () => fetchParticipants({ eventId }),
      setParticipantsLoading,
      setNotification
    )
    if (response?.status === "success") setParticipants(response.data)
  }, [eventId, setParticipantsLoading, setNotification])

  const refreshData = useCallback(async () => {
    await Promise.all([loadSettings(), loadDailyPickemList()]).catch((err) => console.error(err))
  }, [loadSettings, loadDailyPickemList])

  useEffect(() => {
    void loadEvent()
  }, [loadEvent])

  useEffect(() => {
    void loadSettings()
  }, [loadSettings])

  useEffect(() => {
    void loadDailyPickemList()
  }, [loadDailyPickemList])

  useEffect(() => {
    void loadParticipants()
  }, [loadParticipants])

  return (
    <PickemSettingsContext.Provider
      value={{
        setSaving,
        loading,
        eventId,
        eventState,
        setEventState,
        settings,
        setSettings,
        dailyPickemList,
        setDailyPickemList,
        participants,
        setParticipants,
        refreshData,
      }}
    >
      {children}
    </PickemSettingsContext.Provider>
  )
}
