import { useContext, useState } from "react"

import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Fab, Stack, Tooltip, Typography } from "@mui/material"
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog"
import { PickemSettingsContext } from "contexts/pickem"
import { useTranslation } from "react-i18next"
import {
  awardDailyPickemPoints,
  saveDailyPickem,
  type DailyPickemAny,
  type DailyPickemModified,
  type DailyPickemNew,
} from "services/Taiyoro/Pickem/daily"

import { DailyPickemModal } from "./DailyPickemModal"
import { DailyPickemTable } from "./DailyPickemTable"

export const DailyPickemSettings = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    t,
    i18n: { language },
  } = useTranslation("taiyoro")
  const { dailyPickemList, setDailyPickemList, refreshData, setSaving, loading, eventState } =
    useContext(PickemSettingsContext)
  const [selectedDailyPickem, setSelectedDailyPickem] = useState<DailyPickemAny | null>(null)

  const [correctAnswerModalOpen, setCorrectAnswerModalOpen] = useState(false)
  const [correctAnswerModalAnswer, setCorrectAnswerModalAnswer] = useState("")
  const [correctAnswerModalAnswerContent, setCorrectAnswerModalAnswerContent] = useState("")
  const [correctAnswerModalPickemId, setCorrectAnswerModalPickemId] = useState("")

  const [awardPointsModalOpen, setAwardPointsModalOpen] = useState(false)
  const [awardPointsModalPickemId, setAwardPointsModalPickemId] = useState("")

  const [selectedDateTaiyoroRating, setSelectedDateTaiyoroRating] = useState<number | undefined>(undefined)

  const addDailyPickem = (dailyPickem: DailyPickemNew) => {
    setDailyPickemList([...dailyPickemList, dailyPickem])
  }

  const updateDailyPickem = (dailyPickem: DailyPickemModified | DailyPickemNew) => {
    const newDailyPickemList = [...dailyPickemList]
    newDailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickem.id)] = dailyPickem
    setDailyPickemList(newDailyPickemList)
  }

  const deleteDailyPickem = (dailyPickemId: string) => {
    const dailyPickemStatus = dailyPickemList.find((value) => value.id === dailyPickemId)?.status

    if (!dailyPickemStatus) return

    if (dailyPickemStatus === "saved" || dailyPickemStatus === "modified") {
      const newDailyPickemList = [...dailyPickemList]
      newDailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickemId)].status =
        dailyPickemStatus === "saved" ? "deletedFromSaved" : "deletedFromModified"
      setDailyPickemList(newDailyPickemList)
    }

    if (dailyPickemStatus === "new") {
      const newDailyPickemList = dailyPickemList.filter((value) => value.id !== dailyPickemId)
      setDailyPickemList(newDailyPickemList)
    }
  }

  const restoreDailyPickem = (dailyPickemId: string) => {
    const dailyPickemStatus = dailyPickemList.find((value) => value.id === dailyPickemId)?.status

    if (!dailyPickemStatus) return

    const newDailyPickemList = [...dailyPickemList]
    newDailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickemId)].status =
      dailyPickemStatus === "deletedFromSaved" ? "saved" : "modified"
    setDailyPickemList(newDailyPickemList)
  }

  const editDailyPickem = (dailyPickemId: string) => {
    const selected = dailyPickemList.find((value) => value.id === dailyPickemId)
    if (!selected) return

    setIsOpen(true)
    setSelectedDailyPickem(selected)
  }

  const stageCorrectAnswer = (
    dailyPickemId: string,
    answer: {
      id: string
      content: string
      contentJa: string
    }
  ) => {
    setCorrectAnswerModalPickemId(dailyPickemId)
    setCorrectAnswerModalOpen(true)
    setCorrectAnswerModalAnswer(answer.id)
    setCorrectAnswerModalAnswerContent(
      language === "ja" && answer.contentJa && answer.contentJa !== "" ? answer.contentJa : answer.content
    )
  }

  const setCorrectAnswer = async (dailyPickemId: string, correctAnswer: string) => {
    if (loading) return

    const pickem = dailyPickemList.find((value) => value.id === dailyPickemId)
    if (!pickem) return

    const dailyPickem = {
      ...dailyPickemList[dailyPickemList.findIndex((value) => value.id === dailyPickemId)],
    }
    if (dailyPickem.status !== "saved") {
      return
    }

    setSaving(true)

    dailyPickem.correctAnswer = correctAnswer

    await saveDailyPickem({ dailyPickem })
      .then((response) => {
        if (response.status === "success") {
          void refreshData()
        } else {
          console.error("An error occurred while saving the correct answer.")
        }
      })
      .finally(() => {
        setCorrectAnswerModalOpen(false)
        setSaving(false)
      })
  }

  const stageAwardPoints = (dailyPickemId: string) => {
    if (!eventState) return

    const pickem = dailyPickemList.find((pickem) => pickem.id === dailyPickemId)
    if (!pickem) return

    const date = eventState.dates.find((date) => date.id === pickem.dateId)
    if (!date) return

    setSelectedDateTaiyoroRating(date.taiyoroRating)

    setAwardPointsModalOpen(true)
    setAwardPointsModalPickemId(dailyPickemId)
  }

  const awardPoints = async (dailyPickemId: string) => {
    if (loading) return

    setSaving(true)

    await awardDailyPickemPoints({ dailyPickemId })
      .then((response) => {
        if (response.status === "success") {
          void refreshData()
        } else {
          console.error("An error occurred while awarding points.")
        }
      })
      .finally(() => {
        setSaving(false)
        setAwardPointsModalOpen(false)
      })
  }

  return (
    <Box
      sx={{ width: "100%" }}
      mt={2}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h5"
          component="h3"
          mt={3}
          mb={3}
        >
          {t("eventPickem.daily.subtitle")}
        </Typography>
        <Tooltip title={t("eventPickem.daily.newPickem")}>
          <Fab
            size="medium"
            color="success"
            aria-label="add"
            onClick={() => {
              setSelectedDailyPickem(null)
              setIsOpen(true)
            }}
            sx={{
              flexShrink: 0,
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Fab>
        </Tooltip>
      </Stack>
      <DailyPickemModal
        isOpen={isOpen}
        onClose={() => {
          setSelectedDailyPickem(null)
          setIsOpen(false)
        }}
        selectedDailyPickem={selectedDailyPickem}
        addDailyPickem={addDailyPickem}
        updateDailyPickem={updateDailyPickem}
      />
      <DailyPickemTable
        dailyPickemList={dailyPickemList}
        editDailyPickem={editDailyPickem}
        deleteDailyPickem={deleteDailyPickem}
        restoreDailyPickem={restoreDailyPickem}
        stageCorrectAnswer={stageCorrectAnswer}
        stageAwardPoints={stageAwardPoints}
      />
      <ConfirmationDialog
        open={correctAnswerModalOpen}
        cancelText={t("common:actions.cancel")}
        confirmText={t("common:actions.confirm")}
        onCancel={() => {
          setCorrectAnswerModalOpen(false)
          setCorrectAnswerModalPickemId("")
          setCorrectAnswerModalAnswer("")
          setCorrectAnswerModalAnswerContent("")
        }}
        onConfirm={() => {
          void setCorrectAnswer(correctAnswerModalPickemId, correctAnswerModalAnswer)
        }}
        title={t("eventPickem.daily.confirmationModal.titleCorrectAnswer")}
        body={t("eventPickem.daily.confirmationModal.bodyCorrectAnswer", {
          answer: correctAnswerModalAnswerContent,
        })}
      />
      <ConfirmationDialog
        open={awardPointsModalOpen}
        cancelText={t("common:actions.cancel")}
        confirmText={t("common:actions.confirm")}
        onCancel={() => {
          setAwardPointsModalOpen(false)
          setAwardPointsModalPickemId("")
        }}
        onConfirm={() => {
          void awardPoints(awardPointsModalPickemId)
        }}
        title={t("eventPickem.daily.confirmationModal.titleAwardPoints")}
        body={t("eventPickem.daily.confirmationModal.bodyAwardPoints", {
          answer: correctAnswerModalAnswerContent,
        })}
      >
        {selectedDateTaiyoroRating && selectedDateTaiyoroRating > 0 && (
          <Typography sx={{ mt: 1 }}>
            {t("eventPickem.daily.confirmationModal.rating", {
              rating: (selectedDateTaiyoroRating / 20).toFixed(1),
            })}
          </Typography>
        )}
        {(!selectedDateTaiyoroRating || selectedDateTaiyoroRating < 0) && (
          <>
            <Typography sx={{ mt: 1, color: "red", fontWeight: "bold" }}>
              {t("eventPickem.daily.confirmationModal.noRating")}
            </Typography>
            <Typography sx={{ mt: 1, color: "red", fontWeight: "bold" }}>
              {t("eventPickem.daily.confirmationModal.scoreMultiplier")}
            </Typography>
          </>
        )}
      </ConfirmationDialog>
    </Box>
  )
}
